import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./product.css"; // Pastikan CSS telah ditetapkan
import Taswanita from "../asset/taswanita.png";
import Sepatuwanita from "../asset/sepatuwanita.png";
import Pakaianwanita from "../asset/pakaianwanita.png";
import Tekstil from "../asset/Tekstil.png";
import atasanwanita from "../asset/atasanwanita.png";
import jumpsuits from "../asset/jumpsuits.png";
import celana from "../asset/celanawanita.png";
import bajutidur from "../asset/bajutidur.png";
import Jaket from "../asset/jaket.png";
import beachwaer from "../asset/beachwaer.png";
import dress from "../asset/dress.png";
import rok from "../asset/rok.png";
import jeans from "../asset/jeans.png";
import knitwears from "../asset/knitewear.png";
import blazer from "../asset/blazzer.png";
import flats from "../asset/flats.png";
import heels from "../asset/heels.png";
import sandal from "../asset/sandalwanita.png";
import wedges from "../asset/Wedges.png";
import Slipon from "../asset/slipon.png";
import boots from "../asset/boots.png";
import sneakers from "../asset/sneakers.png";
import Sepatu_Olahraga from "../asset/Sepatu_Olahraga.png";
import slingbag from "../asset/slingbag.png";
import handbag from "../asset/handbag.png";
import totebag from "../asset/totabag.png";
import Pouch from "../asset/Pouch.png";
import Backpack from "../asset/backpack.png";
import Tastravel from "../asset/tastravel.png";
import Dompet from "../asset/dompet.png";
import Shopper from "../asset/Shopper.png";
import Taskulit from "../asset/taskulit.png";

// Array untuk kategori
const categories = [
	{ name: "Tas Wanita", image: Taswanita },
	{ name: "Pakaian Wanita", image: Pakaianwanita },
	{ name: "Sepatu Wanita", image: Sepatuwanita },
	{ name: "Tekstil", image: Tekstil },
];

// Array untuk semua produk
const allProducts = [
	{
		name: "Atasan Wanita",
		image: atasanwanita,
		description: `Tampil anggun dan stylish dengan Atasan Wanita kami yang dirancang untuk menonjolkan gaya modern dan elegan. Terbuat dari bahan berkualitas tinggi yang nyaman di kulit, atasan ini tersedia dalam berbagai model—mulai dari kasual hingga formal—cocok untuk berbagai acara.`,
		bahan: "Katun, Poliester",
	},
	{
		name: "Playsuits & Jumpsuits",
		image: jumpsuits,
		description: `Tampil chic dan effortlessly stylish dengan koleksi Playsuits & Jumpsuits wanita kami! Dirancang untuk memberikan tampilan yang trendi sekaligus praktis.`,
		bahan: "Katun, Poliester",
	},
	{
		name: "Celana & Legging",
		image: celana,
		description: `Nikmati kenyamanan maksimal dan fleksibilitas dengan Celana & Legging wanita kami. Terbuat dari bahan elastis berkualitas yang mengikuti bentuk tubuh.`,
		bahan: "Kapas, Spandeks",
	},
	{
		name: "Baju Tidur",
		image: bajutidur,
		description: `Tidur lebih nyaman dan nyenyak dengan Baju Tidur wanita kami yang lembut dan breathable. Terbuat dari bahan yang ringan dan halus di kulit, memastikan kenyamanan tidur Anda.`,
		bahan: "Sutra, Katun",
	},
	{
		name: "Jaket & Coats",
		image: Jaket,
		description: `Tampil elegan dan percaya diri dengan Jaket & Coats wanita kami. Didesain untuk memberikan kehangatan sekaligus tampilan stylish, koleksi ini menggunakan bahan berkualitas tinggi yang tahan lama. Cocok untuk cuaca dingin maupun saat ingin tampil lebih formal.`,
		bahan: "Wol, Poliester",
	},
	{
		name: "Swimwear & Beachwear",
		image: beachwaer,
		description: `Tampil percaya diri dan stylish di pantai dengan koleksi Swimwear & Beachwear kami. Didesain dengan potongan yang modern dan bahan yang cepat kering, swimwear ini memberikan kenyamanan saat berenang atau bersantai di tepi pantai. Tersedia dalam berbagai model yang trendy, koleksi ini siap membuat Anda terlihat fashionable di setiap momen santai Anda!`,
		bahan: "Nilon, Spandeks",
	},
	{
		name: "Dress",
		image: dress,
		description: `Tampil feminin dan elegan dengan koleksi Dress wanita kami yang dirancang untuk berbagai kesempatan. Dengan potongan yang mempesona dan bahan yang nyaman di kulit, dress ini cocok untuk acara formal maupun kasual. Baik itu untuk pesta, makan malam, atau sekadar hangout, dress kami akan membuat Anda tampil memukau dan percaya diri!`,
		bahan: "Katun, Poliester",
	},
	{
		name: "Rok",
		image: rok,
		description: `Tampil anggun dan modis dengan koleksi Rok wanita kami. Terbuat dari bahan yang ringan dan nyaman, rok ini tersedia dalam berbagai desain, mulai dari kasual hingga formal. Desain yang modern dan potongan yang pas akan membuat penampilan Anda semakin stylish di setiap kesempatan. Pilih rok yang tepat untuk menyempurnakan gaya Anda!`,
		bahan: "Katun, Poliester",
	},
	{
		name: "Jeans",
		image: jeans,
		description: `Jeans wanita kami dirancang untuk memberikan kenyamanan dan gaya dalam satu paket. Terbuat dari bahan denim berkualitas yang fleksibel, jeans ini cocok untuk berbagai aktivitas dan mudah dipadukan dengan berbagai outfit. Baik untuk tampilan kasual sehari-hari atau untuk acara santai, jeans kami adalah pilihan sempurna untuk gaya yang selalu on point!`,
		bahan: "Denim",
	},
	{
		name: "Knitwear & Cardigan",
		image: knitwears,
		description: `Tampil cozy dan stylish dengan Knitwear & Cardigan wanita kami. Dibuat dari bahan rajut berkualitas, koleksi ini memberikan kehangatan dan kenyamanan ekstra di setiap kesempatan. Cocok dipadukan dengan berbagai outfit untuk tampilan yang lebih kasual maupun formal. Knitwear & Cardigan kami siap menyempurnakan gaya layering Anda!`,
		bahan: "Akrilik, Wol",
	},
	{
		name: "Blazer",
		image: blazer,
		description: `Tampilkan sisi profesional dan elegan Anda dengan Blazer wanita kami. Didesain untuk memberikan siluet yang pas dan modern, blazer ini sangat cocok untuk dipakai di kantor atau acara formal. Terbuat dari bahan berkualitas yang nyaman dipakai sepanjang hari, blazer kami akan meningkatkan kepercayaan diri Anda dalam berbagai kesempatan. Jadikan blazer ini pilihan utama untuk melengkapi tampilan formal Anda!`,
		bahan: "Katun, Poliester",
	},
	{
		name: "Flats",
		image: flats,
		description: `Temukan kenyamanan dan gaya dalam satu langkah dengan sepatu Flats wanita kami! Didesain dengan sol datar yang fleksibel dan bantalan empuk, sepatu ini sempurna untuk aktivitas sehari-hari tanpa mengorbankan penampilan. Cocok untuk segala suasana, baik formal maupun kasual, sepatu ini hadir dalam berbagai desain elegan dan terbuat dari bahan berkualitas yang tahan lama. Pilih dari berbagai warna dan ukuran, dan rasakan kenyamanan maksimal dengan tampilan yang tetap stylish. Dapatkan sepatu Flats ini sekarang dan buat setiap langkah terasa ringan!`,
		bahan: "Katun, Poliester",
	},
	{
		name: "Heels",
		image: heels,
		description:
			"Tampil percaya diri dan anggun dengan sepatu Heels wanita kami! Dirancang untuk memberikan sentuhan elegan pada setiap penampilan, sepatu hak tinggi ini menawarkan keseimbangan sempurna antara gaya dan kenyamanan. Dengan desain yang memukau serta bahan berkualitas premium, sepatu ini cocok untuk berbagai acara, mulai dari pertemuan formal hingga pesta. Pilihan model yang beragam memungkinkan Anda tampil memukau dalam setiap kesempatan. Raih perhatian dan tingkatkan gaya Anda dengan koleksi Heels kami, yang siap membuat setiap langkah lebih berkelas!",
		bahan: "Kulit Sintetis",
	},
	{
		name: "Sandal",
		image: sandal,
		description:
			"Nikmati kenyamanan maksimal tanpa mengorbankan gaya dengan Sandal wanita kami! Dibuat dari bahan berkualitas tinggi dan desain ergonomis, sandal ini menawarkan kenyamanan luar biasa untuk aktivitas sehari-hari. Tersedia dalam berbagai model modern, mulai dari kasual hingga semi-formal, sandal ini cocok untuk menemani Anda di segala suasana. Dengan sol yang ringan dan fleksibel, kaki Anda akan terasa nyaman sepanjang hari. Tampil santai namun tetap stylish dengan sandal yang praktis dan modis ini—pilihan sempurna untuk setiap langkah Anda!",
		bahan: "Kanvas",
	},
	{
		name: "Wedges",
		image: wedges,
		description:
			"Tampil lebih tinggi dan percaya diri tanpa mengorbankan kenyamanan dengan Wedges wanita kami! Sepatu dengan sol tebal yang menyatu dari depan hingga belakang ini memberikan stabilitas dan kenyamanan lebih dibandingkan heels biasa, sambil tetap memberikan sentuhan elegan pada penampilan Anda. Ideal untuk berbagai acara, baik formal maupun santai, wedges kami terbuat dari bahan berkualitas dengan desain stylish yang akan melengkapi gaya Anda. Dapatkan ketinggian dan kenyamanan dalam satu paket dengan koleksi Wedges kami yang siap membuat setiap langkah Anda semakin memukau!",
		bahan: "Poliester",
	},
	{
		name: "Slip On",
		image: Slipon,
		description:
			"Nikmati kemudahan dan kenyamanan maksimal dengan Slip On wanita kami! Didesain tanpa tali, sepatu ini sangat praktis untuk dipakai dan dilepas, menjadikannya pilihan ideal untuk gaya hidup aktif. Dengan sol yang empuk dan bahan berkualitas, slip on ini memberikan kenyamanan sepanjang hari tanpa mengorbankan penampilan. Cocok untuk berbagai kesempatan, dari kasual hingga semi-formal, slip on kami hadir dalam desain modern yang stylish. Tampil effortlessly chic dengan Slip On yang nyaman dan modis ini, sempurna untuk setiap aktivitas harian Anda!",
		bahan: "Poliester",
	},
	{
		name: "Boots",
		image: boots,
		description:
			"Tampil bold dan stylish dengan Boots wanita kami! Didesain untuk memberikan tampilan kuat dan elegan, boots ini tidak hanya melindungi kaki Anda dari berbagai cuaca, tetapi juga menambahkan sentuhan fashion statement pada setiap outfit. Terbuat dari bahan berkualitas tinggi dengan sol yang kokoh, boots kami memastikan kenyamanan dan daya tahan maksimal. Cocok untuk berbagai gaya, baik kasual maupun formal, boots ini siap menemani Anda dalam setiap kesempatan. Berani tampil beda dan penuh gaya dengan koleksi Boots kami yang siap memukau setiap langkah Anda!",
		bahan: "Poliester",
	},
	{
		name: "Sneakers",
		image: sneakers,
		description:
			"Tampil sporty dan trendi dengan Sneakers wanita kami! Didesain untuk kenyamanan dan gaya, sneakers ini cocok untuk segala aktivitas, mulai dari jalan santai hingga olahraga ringan. Dengan bantalan empuk dan sol yang fleksibel, sepatu ini memberikan dukungan maksimal untuk kaki Anda sepanjang hari. Terbuat dari bahan berkualitas dan hadir dalam desain modern yang stylish, sneakers ini mudah dipadukan dengan berbagai outfit. Nikmati kenyamanan dan tampilan fresh dengan sneakers yang siap menemani setiap langkah aktif Anda!",
		bahan: "Poliester",
	},
	{
		name: "Sepatu Olahraga",
		image: Sepatu_Olahraga,
		description:
			"Tingkatkan performa olahraga Anda dengan Sepatu Olahraga wanita kami! Didesain khusus untuk memberikan kenyamanan dan dukungan optimal, sepatu ini dilengkapi dengan bantalan empuk dan sol yang fleksibel untuk mengurangi tekanan pada kaki saat berolahraga. Terbuat dari bahan yang ringan dan bernapas, sepatu ini menjaga kaki tetap sejuk dan nyaman, bahkan selama aktivitas intens. Cocok untuk berbagai jenis olahraga, mulai dari lari hingga latihan di gym, sepatu olahraga kami menawarkan kombinasi sempurna antara fungsi dan gaya. Raih performa terbaik dengan sepatu olahraga yang mendukung setiap langkah aktif Anda!.",
		bahan: "Poliester",
	},
	{
		name: "Slingbag",
		image: slingbag,
		description: `Tingkatkan gaya Anda dengan sling bag elegan yang dirancang khusus untuk melengkapi berbagai kesempatan. Desainnya yang anggun dan material berkualitas tinggi membuatnya mudah dipadukan dengan busana formal seperti setelan bisnis atau gaun malam, sekaligus tetap stylish saat digunakan dengan pakaian kasual sehari-hari. Fitur multifungsionalnya memungkinkan Anda membawa semua kebutuhan penting tanpa mengorbankan penampilan. Baik untuk pertemuan bisnis, acara sosial, perjalanan santai, maupun kegiatan sehari-hari, sling bag ini menjadi aksesori yang sempurna untuk menambah kesan sofistic dan praktis dalam setiap momen Anda.`,
		bahan: "Katun, Poliester",
	},
	{
		name: "Handbag",
		image: handbag,
		description:
			"Tas tangan eksklusif yang diproduksi dengan bahan-bahan berkualitas tinggi dan dikerjakan dengan keterampilan tangan yang teliti, memastikan daya tahan serta keindahan yang tahan lama. Desainnya mengusung konsep modern dengan perpaduan gaya kontemporer yang canggih dan fungsionalitas yang elegan, menjadikannya aksesori yang sempurna untuk melengkapi berbagai penampilan, baik formal maupun kasual. Dengan perhatian terhadap setiap detail, tas ini tidak hanya menawarkan kenyamanan dalam penggunaan sehari-hari tetapi juga menjadi simbol gaya hidup yang mewah dan berkelas",
		bahan: "Kulit Sintetis",
	},
	{
		name: "Totebag",
		image: totebag,
		description:
			"Totebag berukuran luas yang dirancang untuk memenuhi kebutuhan sehari-hari, dengan kapasitas besar yang mampu menampung berbagai barang penting, mulai dari laptop, buku, hingga perlengkapan lainnya. Dibuat dari bahan yang tahan lama dan berkualitas tinggi, tas ini menawarkan kepraktisan tanpa mengorbankan gaya. Desainnya yang sederhana namun elegan menjadikannya aksesori yang cocok digunakan dalam berbagai aktivitas, baik untuk bekerja, berbelanja, atau bersantai. Totebag ini adalah pilihan sempurna bagi mereka yang membutuhkan fungsionalitas maksimal dengan tampilan modern dan stylish.",
		bahan: "Kanvas",
	},
	{
		name: "Pouch",
		image: Pouch,
		description:
			"Pouch multifungsi yang praktis, dirancang khusus untuk menyimpan dan mengorganisir barang-barang kecil Anda seperti kunci, kartu, kosmetik, atau aksesoris lainnya. Dibuat dari material berkualitas tinggi yang tahan lama dan mudah dibersihkan, pouch ini menawarkan kombinasi sempurna antara fungsionalitas dan estetika. Dengan ukuran yang compact, pouch ini sangat mudah dibawa ke mana saja, baik di dalam tas besar ataupun digunakan sendiri. Desainnya yang modern dan elegan menjadikannya pilihan yang tepat bagi Anda yang ingin tetap rapi dan teratur tanpa mengorbankan gaya.",
		bahan: "Poliester",
	},
	{
		name: "Backpack",
		image: Backpack,
		description:
			"Backpack serbaguna yang dirancang secara khusus untuk memenuhi kebutuhan aktivitas sehari-hari, baik itu untuk bekerja, berkuliah, ataupun bepergian. Dengan desain ergonomis, tas ini memastikan kenyamanan saat digunakan dalam jangka waktu lama. Kompartemen utamanya yang luas memungkinkan Anda membawa berbagai barang penting, seperti laptop, buku, dokumen, hingga barang pribadi lainnya dengan mudah dan terorganisir. Tersedia juga beberapa kantong tambahan yang memudahkan penyimpanan barang-barang kecil agar selalu mudah dijangkau.",
		bahan: "Poliester",
	},
	{
		name: "Tas Travel",
		image: Tastravel,
		description:
			"Tas travel yang didesain secara khusus untuk memenuhi kebutuhan perjalanan Anda, menawarkan ruang yang luas dan fungsionalitas optimal. Dengan kompartemen utama yang besar, tas ini mampu memuat berbagai barang penting untuk perjalanan jarak dekat maupun jauh, seperti pakaian, sepatu, perlengkapan mandi, dan barang-barang pribadi lainnya. Terdapat juga beberapa kantong tambahan, baik di dalam maupun di luar, yang memudahkan Anda dalam menyimpan barang-barang kecil seperti paspor, charger, dan gadget agar selalu mudah diakses.",
		bahan: "Poliester",
	},
	{
		name: "Dompet",
		image: Dompet,
		description:
			"Dompet elegan yang dirancang untuk memenuhi kebutuhan Anda dalam menyimpan berbagai kartu, uang tunai, dan dokumen kecil lainnya dengan aman dan rapi. Dibuat dari bahan berkualitas tinggi, dompet ini menawarkan daya tahan yang kuat dan tampilan yang mewah. Dengan beberapa slot kartu, kantong untuk uang tunai, serta ruang tambahan untuk menyimpan kuitansi atau dokumen penting, dompet ini menggabungkan fungsionalitas dan kenyamanan dalam satu desain yang ringkas.",
		bahan: "Poliester",
	},
	{
		name: "Shopper bag",
		image: Shopper,
		description:
			"Shopper bag yang luas dan stylish, dirancang untuk memberikan kemudahan dalam membawa berbagai barang kebutuhan harian dengan tetap mempertahankan tampilan yang trendi. Tas ini memiliki kapasitas besar yang memungkinkan Anda membawa segala sesuatu mulai dari belanjaan, laptop, hingga barang-barang pribadi lainnya, menjadikannya pilihan ideal untuk aktivitas sehari-hari maupun perjalanan singkat. Dengan kantong-kantong tambahan di bagian dalam, tas ini juga membantu mengorganisir barang-barang kecil seperti dompet, kunci, dan ponsel agar mudah dijangkau.",
		bahan: "Poliester",
	},
	{
		name: "Tas Kulit",
		image: Taskulit,
		description:
			"Tas kulit mewah yang dirancang dengan keanggunan dan keahlian tingkat tinggi, menawarkan perpaduan sempurna antara fungsionalitas dan gaya. Terbuat dari kulit asli berkualitas premium, tas ini menampilkan tekstur yang halus dan tahan lama, memberikan kesan elegan yang semakin indah seiring waktu. Ruang penyimpanannya yang luas dan terorganisir dengan baik memungkinkan Anda membawa barang-barang penting sehari-hari, seperti laptop, buku, dan dokumen, serta barang-barang pribadi lainnya dengan mudah dan rapi.",
		bahan: "Poliester",
	},
];

const App = () => {
	const navigate = useNavigate();
	const [selectedProduct, setSelectedProduct] = useState(null);

	const closePopup = () => {
		setSelectedProduct(null);
	};

	const handleConsultation = () => {
		navigate("/register");
	};

	return (
		<>
			{/* Bagian Kategori */}
			<div className="container">
				<h1 className="title">Kategori</h1>
				<div className="product-list1">
					{categories.map((category, index) => (
						<div
							className="product-card"
							key={index}
						>
							<a
								href={`Category_${category.name
									.replace(/\s/g, "")
									.toLowerCase()}`}
							>
								<img
									src={category.image}
									alt={`${category.name} Image`}
								/>
								<h3>{category.name}</h3>
							</a>
						</div>
					))}
				</div>
			</div>

			{/* Bagian Semua Produk */}
			<div className="container">
				<h1 className="title">Semua Produk</h1>
				<div className="product-list1">
					{allProducts.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)}
						>
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>

				{/* Popup Produk */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.description}</p>
									<p>
										<strong>Bahan:</strong> {selectedProduct.bahan}
									</p>
									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Konsultasi
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default App;
