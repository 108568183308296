import React, { useState } from "react";
import "./Navbar.css";
import Logo from "../asset/Logo.png"; // Pastikan path ini benar
import { Link } from "react-router-dom";

function Navbar() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<nav>
			<div className="container">
				<a href="#">
					<img
						src={Logo}
						alt="SatuLini Logo"
						className="logo"
					/>
				</a>
				<div className={`nav-links ${isOpen ? "open" : ""}`}>
					<ul>
						<li>
							<Link
								to="/"
								onClick={toggleMenu}
							>
								Home
							</Link>
						</li>
						<li>
							<Link
								to="/product"
								onClick={toggleMenu}
							>
								Product
							</Link>
						</li>
						<li>
							<Link
								to="/fitur"
								onClick={toggleMenu}
							>
								Fitur
							</Link>
						</li>
						<li>
							<Link
								to="/allert"
								onClick={toggleMenu}
							>
								Berita
							</Link>
						</li>
						<li>
							<Link
								to="/allert"
								onClick={toggleMenu}
							>
								Bantuan
							</Link>
						</li>
					</ul>
				</div>
				<div className="nav-right">
					<Link
						to="/login"
						className="btn-primary"
					>
						Login
					</Link>
					<Link
						to="/register"
						className="btn-primary"
					>
						Register
					</Link>
				</div>
				<div
					className="menu-icon"
					onClick={toggleMenu}
				>
					<i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
				</div>
			</div>
		</nav>
	);
}

export default Navbar;
