import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Pastikan useNavigate diimpor
import "./product.css"; // Atur CSS di file terpisah atau sesuaikan inline
import slingbag from "../../asset/slingbag.png";
import handbag from "../../asset/handbag.png";
import totebag from "../../asset/totabag.png";
import Pouch from "../../asset/Pouch.png";
import Backpack from "../../asset/backpack.png";
import Tastravel from "../../asset/tastravel.png";
import Dompet from "../../asset/dompet.png";
import Shopper from "../../asset/Shopper.png";
import Taskulit from "../../asset/taskulit.png";

const App = () => {
	const navigate = useNavigate(); // Hook untuk navigasi
	const [selectedProduct, setSelectedProduct] = useState(null); // State untuk popup
	const products = [
		{
			name: "Slingbag",
			image: slingbag,
			description: `Tingkatkan gaya Anda dengan sling bag elegan yang dirancang khusus untuk melengkapi berbagai kesempatan. Desainnya yang anggun dan material berkualitas tinggi membuatnya mudah dipadukan dengan busana formal seperti setelan bisnis atau gaun malam, sekaligus tetap stylish saat digunakan dengan pakaian kasual sehari-hari. Fitur multifungsionalnya memungkinkan Anda membawa semua kebutuhan penting tanpa mengorbankan penampilan. Baik untuk pertemuan bisnis, acara sosial, perjalanan santai, maupun kegiatan sehari-hari, sling bag ini menjadi aksesori yang sempurna untuk menambah kesan sofistic dan praktis dalam setiap momen Anda.`,
			bahan: "Katun, Poliester",
		},
		{
			name: "Handbag",
			image: handbag,
			description:
				"Tas tangan eksklusif yang diproduksi dengan bahan-bahan berkualitas tinggi dan dikerjakan dengan keterampilan tangan yang teliti, memastikan daya tahan serta keindahan yang tahan lama. Desainnya mengusung konsep modern dengan perpaduan gaya kontemporer yang canggih dan fungsionalitas yang elegan, menjadikannya aksesori yang sempurna untuk melengkapi berbagai penampilan, baik formal maupun kasual. Dengan perhatian terhadap setiap detail, tas ini tidak hanya menawarkan kenyamanan dalam penggunaan sehari-hari tetapi juga menjadi simbol gaya hidup yang mewah dan berkelas",
			bahan: "Kulit Sintetis",
		},
		{
			name: "Totebag",
			image: totebag,
			description:
				"Totebag berukuran luas yang dirancang untuk memenuhi kebutuhan sehari-hari, dengan kapasitas besar yang mampu menampung berbagai barang penting, mulai dari laptop, buku, hingga perlengkapan lainnya. Dibuat dari bahan yang tahan lama dan berkualitas tinggi, tas ini menawarkan kepraktisan tanpa mengorbankan gaya. Desainnya yang sederhana namun elegan menjadikannya aksesori yang cocok digunakan dalam berbagai aktivitas, baik untuk bekerja, berbelanja, atau bersantai. Totebag ini adalah pilihan sempurna bagi mereka yang membutuhkan fungsionalitas maksimal dengan tampilan modern dan stylish.",
			bahan: "Kanvas",
		},
		{
			name: "Pouch",
			image: Pouch,
			description:
				"Pouch multifungsi yang praktis, dirancang khusus untuk menyimpan dan mengorganisir barang-barang kecil Anda seperti kunci, kartu, kosmetik, atau aksesoris lainnya. Dibuat dari material berkualitas tinggi yang tahan lama dan mudah dibersihkan, pouch ini menawarkan kombinasi sempurna antara fungsionalitas dan estetika. Dengan ukuran yang compact, pouch ini sangat mudah dibawa ke mana saja, baik di dalam tas besar ataupun digunakan sendiri. Desainnya yang modern dan elegan menjadikannya pilihan yang tepat bagi Anda yang ingin tetap rapi dan teratur tanpa mengorbankan gaya.",
			bahan: "Poliester",
		},
		{
			name: "Backpack",
			image: Backpack,
			description:
				"Backpack serbaguna yang dirancang secara khusus untuk memenuhi kebutuhan aktivitas sehari-hari, baik itu untuk bekerja, berkuliah, ataupun bepergian. Dengan desain ergonomis, tas ini memastikan kenyamanan saat digunakan dalam jangka waktu lama. Kompartemen utamanya yang luas memungkinkan Anda membawa berbagai barang penting, seperti laptop, buku, dokumen, hingga barang pribadi lainnya dengan mudah dan terorganisir. Tersedia juga beberapa kantong tambahan yang memudahkan penyimpanan barang-barang kecil agar selalu mudah dijangkau.",
			bahan: "Poliester",
		},
		{
			name: "Tas Travel",
			image: Tastravel,
			description:
				"Tas travel yang didesain secara khusus untuk memenuhi kebutuhan perjalanan Anda, menawarkan ruang yang luas dan fungsionalitas optimal. Dengan kompartemen utama yang besar, tas ini mampu memuat berbagai barang penting untuk perjalanan jarak dekat maupun jauh, seperti pakaian, sepatu, perlengkapan mandi, dan barang-barang pribadi lainnya. Terdapat juga beberapa kantong tambahan, baik di dalam maupun di luar, yang memudahkan Anda dalam menyimpan barang-barang kecil seperti paspor, charger, dan gadget agar selalu mudah diakses.",
			bahan: "Poliester",
		},
		{
			name: "Dompet",
			image: Dompet,
			description:
				"Dompet elegan yang dirancang untuk memenuhi kebutuhan Anda dalam menyimpan berbagai kartu, uang tunai, dan dokumen kecil lainnya dengan aman dan rapi. Dibuat dari bahan berkualitas tinggi, dompet ini menawarkan daya tahan yang kuat dan tampilan yang mewah. Dengan beberapa slot kartu, kantong untuk uang tunai, serta ruang tambahan untuk menyimpan kuitansi atau dokumen penting, dompet ini menggabungkan fungsionalitas dan kenyamanan dalam satu desain yang ringkas.",
			bahan: "Poliester",
		},
		{
			name: "Shopper bag",
			image: Shopper,
			description:
				"Shopper bag yang luas dan stylish, dirancang untuk memberikan kemudahan dalam membawa berbagai barang kebutuhan harian dengan tetap mempertahankan tampilan yang trendi. Tas ini memiliki kapasitas besar yang memungkinkan Anda membawa segala sesuatu mulai dari belanjaan, laptop, hingga barang-barang pribadi lainnya, menjadikannya pilihan ideal untuk aktivitas sehari-hari maupun perjalanan singkat. Dengan kantong-kantong tambahan di bagian dalam, tas ini juga membantu mengorganisir barang-barang kecil seperti dompet, kunci, dan ponsel agar mudah dijangkau.",
			bahan: "Poliester",
		},
		{
			name: "Tas Kulit",
			image: Taskulit,
			description:
				"Tas kulit mewah yang dirancang dengan keanggunan dan keahlian tingkat tinggi, menawarkan perpaduan sempurna antara fungsionalitas dan gaya. Terbuat dari kulit asli berkualitas premium, tas ini menampilkan tekstur yang halus dan tahan lama, memberikan kesan elegan yang semakin indah seiring waktu. Ruang penyimpanannya yang luas dan terorganisir dengan baik memungkinkan Anda membawa barang-barang penting sehari-hari, seperti laptop, buku, dan dokumen, serta barang-barang pribadi lainnya dengan mudah dan rapi.",
			bahan: "Poliester",
		},
	];

	// Fungsi untuk menutup popup
	const closePopup = () => {
		setSelectedProduct(null);
	};

	// Fungsi untuk menangani tombol konsultasi
	const handleConsultation = () => {
		navigate("/register"); // Navigasi ke rute "/register"
	};

	return (
		<>
			<div className="container">
				<div className="header-row">
					<h1 className="title">Semua Produk Kategori Pakaian Wanita</h1>
					{/* Tombol Back */}
					<button
						onClick={() => navigate(-1)}
						className="back-button"
					>
						Back
					</button>
				</div>
				<div className="product-list1">
					{products.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)} // Set produk yang dipilih saat diklik
						>
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>

				{/* Popup untuk menampilkan detail produk yang dipilih */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.description}</p>
									<p>
										<strong>Bahan:</strong> {selectedProduct.bahan}
									</p>{" "}
									{/* Display Bahan */}
									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Konsultasi
									</button>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* <div className="pagination">
					<button>&larr;</button>
					<span>1</span>
					<span>2</span>
					<button>&rarr;</button>
				</div> */}
			</div>
		</>
	);
};

export default App;
