// App.js
import React from "react";
import Navbar from "../components/Navbar.js";
import Fitur from "../components/Fiturpage/mentor.js";
import Footer from "../components/Footer.js";

function App() {
	return (
		<div className="App">
			<Navbar />
			<Fitur />
			<Footer />
		</div>
	);
}

export default App;
