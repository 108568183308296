// Import library react-router-dom
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

// Import komponen halaman
import Home from "./Pages/Home.js";
import Product from "./Pages/Product.js";
import Login from "./Pages/Login.js";
import Register from "./Pages/Register.js";
import Allert from "./Pages/Allert.js";
import Fitur from "./Pages/Fitur.js";
import Kategori1 from "./Pages/ProductonlyTaswanita.js";
import Kategori2 from "./Pages/ProductonlyPakaianwanita.js";
import Kategori3 from "./Pages/ProductonlySepatuWanita.js";
import Kategori4 from "./Pages/ProductonlyAksesoris.js";
import Fitur1 from "./Pages/Fitur_KOL.js";
import Fitur2 from "./Pages/Fitur_Affiliate.js";
import Fitur3 from "./Pages/Fitur_Influ.js";
import Fitur4 from "./Pages/Fitur_Mentor.js";

function App() {
	return (
		// Bungkus seluruh aplikasi dengan Router
		<Router>
			<div>
				{/* Daftarkan route dalam Routes */}
				<Routes>
					{/* Route untuk Home sebagai default */}
					<Route
						path="/"
						element={<Navigate to="/home" />}
					/>
					<Route
						path="/home"
						element={<Home />}
					/>
					<Route
						path="/product"
						element={<Product />}
					/>
					<Route
						path="/fitur"
						element={<Fitur />}
					/>
					<Route
						path="/login"
						element={<Login />}
					/>
					<Route
						path="/register"
						element={<Register />}
					/>
					<Route
						path="/allert"
						element={<Allert />}
					/>
					<Route
						path="/Category_Taswanita"
						element={<Kategori1 />}
					/>
					<Route
						path="/Category_Pakaianwanita"
						element={<Kategori2 />}
					/>
					<Route
						path="/Category_Sepatuwanita"
						element={<Kategori3 />}
					/>
					<Route
						path="/Category_tekstil"
						element={<Kategori4 />}
					/>
					<Route
						path="/Category_kolspecialis"
						element={<Fitur1 />}
					/>
					<Route
						path="/Category_influencer"
						element={<Fitur2 />}
					/>
					<Route
						path="/Category_mentorbisnis"
						element={<Fitur4 />}
					/>
					<Route
						path="/Category_affiliate"
						element={<Fitur3 />}
					/>
				</Routes>
			</div>
		</Router>
	);
}

export default App;
