import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Pastikan useNavigate diimpor
import "./product.css"; // Atur CSS di file terpisah atau sesuaikan inline
import batik from "../../asset/batik.png";
import printing from "../../asset/printingkain.png";
import kain_bahan from "../../asset/bahan_kain.png";

const App = () => {
	const navigate = useNavigate(); // Hook untuk navigasi
	const [selectedProduct, setSelectedProduct] = useState(null); // State untuk popup
	const products = [
		{
			name: "Batik",
			image: batik,
			description: `Temukan kenyamanan dan gaya dalam satu langkah dengan sepatu Flats wanita kami! Didesain dengan sol datar yang fleksibel dan bantalan empuk, sepatu ini sempurna untuk aktivitas sehari-hari tanpa mengorbankan penampilan. Cocok untuk segala suasana, baik formal maupun kasual, sepatu ini hadir dalam berbagai desain elegan dan terbuat dari bahan berkualitas yang tahan lama. Pilih dari berbagai warna dan ukuran, dan rasakan kenyamanan maksimal dengan tampilan yang tetap stylish. Dapatkan sepatu Flats ini sekarang dan buat setiap langkah terasa ringan!`,
			bahan: "Katun, Poliester",
		},
		{
			name: "printing",
			image: printing,
			description:
				"Tampil percaya diri dan anggun dengan sepatu Heels wanita kami! Dirancang untuk memberikan sentuhan elegan pada setiap penampilan, sepatu hak tinggi ini menawarkan keseimbangan sempurna antara gaya dan kenyamanan. Dengan desain yang memukau serta bahan berkualitas premium, sepatu ini cocok untuk berbagai acara, mulai dari pertemuan formal hingga pesta. Pilihan model yang beragam memungkinkan Anda tampil memukau dalam setiap kesempatan. Raih perhatian dan tingkatkan gaya Anda dengan koleksi Heels kami, yang siap membuat setiap langkah lebih berkelas!",
			bahan: "Kulit Sintetis",
		},
		{
			name: "kain bahan",
			image: kain_bahan,
			description:
				"Tampil percaya diri dan anggun dengan sepatu Heels wanita kami! Dirancang untuk memberikan sentuhan elegan pada setiap penampilan, sepatu hak tinggi ini menawarkan keseimbangan sempurna antara gaya dan kenyamanan. Dengan desain yang memukau serta bahan berkualitas premium, sepatu ini cocok untuk berbagai acara, mulai dari pertemuan formal hingga pesta. Pilihan model yang beragam memungkinkan Anda tampil memukau dalam setiap kesempatan. Raih perhatian dan tingkatkan gaya Anda dengan koleksi Heels kami, yang siap membuat setiap langkah lebih berkelas!",
			bahan: "Kulit Sintetis",
		},
	];

	// Fungsi untuk menutup popup
	const closePopup = () => {
		setSelectedProduct(null);
	};

	// Fungsi untuk menangani tombol konsultasi
	const handleConsultation = () => {
		navigate("/register"); // Navigasi ke rute "/register"
	};

	return (
		<>
			<div className="container">
				<div className="header-row">
					<h1 className="title">Semua Produk Kategori Pakaian Wanita</h1>
					{/* Tombol Back */}
					<button
						onClick={() => navigate(-1)}
						className="back-button"
					>
						Back
					</button>
				</div>
				<div className="product-list1">
					{products.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)} // Set produk yang dipilih saat diklik
						>
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>

				{/* Popup untuk menampilkan detail produk yang dipilih */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.description}</p>
									<p>
										<strong>Bahan:</strong> {selectedProduct.bahan}
									</p>{" "}
									{/* Display Bahan */}
									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Konsultasi
									</button>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* <div className="pagination">
					<button>&larr;</button>
					<span>1</span>
					<span>2</span>
					<button>&rarr;</button>
				</div> */}
			</div>
		</>
	);
};

export default App;
