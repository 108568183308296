import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Pastikan useNavigate diimpor
import "../product.css"; // Atur CSS di file terpisah atau sesuaikan inline
import flats from "../../asset/flats.png";
import heels from "../../asset/heels.png";
import sandal from "../../asset/sandalwanita.png";
import wedges from "../../asset/Wedges.png";
import Slipon from "../../asset/slipon.png";
import boots from "../../asset/boots.png";
import sneakers from "../../asset/sneakers.png";
import Sepatu_Olahraga from "../../asset/Sepatu_Olahraga.png";

const App = () => {
	const navigate = useNavigate(); // Hook untuk navigasi
	const [selectedProduct, setSelectedProduct] = useState(null); // State untuk popup
	const products = [
		{
			name: "Flats",
			image: flats,
			description: `Temukan kenyamanan dan gaya dalam satu langkah dengan sepatu Flats wanita kami! Didesain dengan sol datar yang fleksibel dan bantalan empuk, sepatu ini sempurna untuk aktivitas sehari-hari tanpa mengorbankan penampilan. Cocok untuk segala suasana, baik formal maupun kasual, sepatu ini hadir dalam berbagai desain elegan dan terbuat dari bahan berkualitas yang tahan lama. Pilih dari berbagai warna dan ukuran, dan rasakan kenyamanan maksimal dengan tampilan yang tetap stylish. Dapatkan sepatu Flats ini sekarang dan buat setiap langkah terasa ringan!`,
			bahan: "Katun, Poliester",
		},
		{
			name: "Heels",
			image: heels,
			description:
				"Tampil percaya diri dan anggun dengan sepatu Heels wanita kami! Dirancang untuk memberikan sentuhan elegan pada setiap penampilan, sepatu hak tinggi ini menawarkan keseimbangan sempurna antara gaya dan kenyamanan. Dengan desain yang memukau serta bahan berkualitas premium, sepatu ini cocok untuk berbagai acara, mulai dari pertemuan formal hingga pesta. Pilihan model yang beragam memungkinkan Anda tampil memukau dalam setiap kesempatan. Raih perhatian dan tingkatkan gaya Anda dengan koleksi Heels kami, yang siap membuat setiap langkah lebih berkelas!",
			bahan: "Kulit Sintetis",
		},
		{
			name: "Sandal",
			image: sandal,
			description:
				"Nikmati kenyamanan maksimal tanpa mengorbankan gaya dengan Sandal wanita kami! Dibuat dari bahan berkualitas tinggi dan desain ergonomis, sandal ini menawarkan kenyamanan luar biasa untuk aktivitas sehari-hari. Tersedia dalam berbagai model modern, mulai dari kasual hingga semi-formal, sandal ini cocok untuk menemani Anda di segala suasana. Dengan sol yang ringan dan fleksibel, kaki Anda akan terasa nyaman sepanjang hari. Tampil santai namun tetap stylish dengan sandal yang praktis dan modis ini—pilihan sempurna untuk setiap langkah Anda!",
			bahan: "Kanvas",
		},
		{
			name: "Wedges",
			image: wedges,
			description:
				"Tampil lebih tinggi dan percaya diri tanpa mengorbankan kenyamanan dengan Wedges wanita kami! Sepatu dengan sol tebal yang menyatu dari depan hingga belakang ini memberikan stabilitas dan kenyamanan lebih dibandingkan heels biasa, sambil tetap memberikan sentuhan elegan pada penampilan Anda. Ideal untuk berbagai acara, baik formal maupun santai, wedges kami terbuat dari bahan berkualitas dengan desain stylish yang akan melengkapi gaya Anda. Dapatkan ketinggian dan kenyamanan dalam satu paket dengan koleksi Wedges kami yang siap membuat setiap langkah Anda semakin memukau!",
			bahan: "Poliester",
		},
		{
			name: "Slip On",
			image: Slipon,
			description:
				"Nikmati kemudahan dan kenyamanan maksimal dengan Slip On wanita kami! Didesain tanpa tali, sepatu ini sangat praktis untuk dipakai dan dilepas, menjadikannya pilihan ideal untuk gaya hidup aktif. Dengan sol yang empuk dan bahan berkualitas, slip on ini memberikan kenyamanan sepanjang hari tanpa mengorbankan penampilan. Cocok untuk berbagai kesempatan, dari kasual hingga semi-formal, slip on kami hadir dalam desain modern yang stylish. Tampil effortlessly chic dengan Slip On yang nyaman dan modis ini, sempurna untuk setiap aktivitas harian Anda!",
			bahan: "Poliester",
		},
		{
			name: "Boots",
			image: boots,
			description:
				"Tampil bold dan stylish dengan Boots wanita kami! Didesain untuk memberikan tampilan kuat dan elegan, boots ini tidak hanya melindungi kaki Anda dari berbagai cuaca, tetapi juga menambahkan sentuhan fashion statement pada setiap outfit. Terbuat dari bahan berkualitas tinggi dengan sol yang kokoh, boots kami memastikan kenyamanan dan daya tahan maksimal. Cocok untuk berbagai gaya, baik kasual maupun formal, boots ini siap menemani Anda dalam setiap kesempatan. Berani tampil beda dan penuh gaya dengan koleksi Boots kami yang siap memukau setiap langkah Anda!",
			bahan: "Poliester",
		},
		{
			name: "Sneakers",
			image: sneakers,
			description:
				"Tampil sporty dan trendi dengan Sneakers wanita kami! Didesain untuk kenyamanan dan gaya, sneakers ini cocok untuk segala aktivitas, mulai dari jalan santai hingga olahraga ringan. Dengan bantalan empuk dan sol yang fleksibel, sepatu ini memberikan dukungan maksimal untuk kaki Anda sepanjang hari. Terbuat dari bahan berkualitas dan hadir dalam desain modern yang stylish, sneakers ini mudah dipadukan dengan berbagai outfit. Nikmati kenyamanan dan tampilan fresh dengan sneakers yang siap menemani setiap langkah aktif Anda!",
			bahan: "Poliester",
		},
		{
			name: "Sepatu Olahraga",
			image: Sepatu_Olahraga,
			description:
				"Tingkatkan performa olahraga Anda dengan Sepatu Olahraga wanita kami! Didesain khusus untuk memberikan kenyamanan dan dukungan optimal, sepatu ini dilengkapi dengan bantalan empuk dan sol yang fleksibel untuk mengurangi tekanan pada kaki saat berolahraga. Terbuat dari bahan yang ringan dan bernapas, sepatu ini menjaga kaki tetap sejuk dan nyaman, bahkan selama aktivitas intens. Cocok untuk berbagai jenis olahraga, mulai dari lari hingga latihan di gym, sepatu olahraga kami menawarkan kombinasi sempurna antara fungsi dan gaya. Raih performa terbaik dengan sepatu olahraga yang mendukung setiap langkah aktif Anda!.",
			bahan: "Poliester",
		},
	];

	// Fungsi untuk menutup popup
	const closePopup = () => {
		setSelectedProduct(null);
	};

	// Fungsi untuk menangani tombol konsultasi
	const handleConsultation = () => {
		navigate("/register"); // Navigasi ke rute "/register"
	};

	return (
		<>
			<div className="container">
				<div className="header-row">
					<h1 className="title">Semua Produk Kategori Pakaian Wanita</h1>
					{/* Tombol Back */}
					<button
						onClick={() => navigate(-1)}
						className="back-button"
					>
						Back
					</button>
				</div>
				<div className="product-list1">
					{products.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)} // Set produk yang dipilih saat diklik
						>
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>

				{/* Popup untuk menampilkan detail produk yang dipilih */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.description}</p>
									<p>
										<strong>Bahan:</strong> {selectedProduct.bahan}
									</p>{" "}
									{/* Display Bahan */}
									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Konsultasi
									</button>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* <div className="pagination">
					<button>&larr;</button>
					<span>1</span>
					<span>2</span>
					<button>&rarr;</button>
				</div> */}
			</div>
		</>
	);
};

export default App;
