import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Pastikan useNavigate diimpor
import "./product.css"; // Atur CSS di file terpisah atau sesuaikan inline
import atasanwanita from "../../asset/atasanwanita.png";
import jumpsuits from "../../asset/jumpsuits.png";
import celana from "../../asset/celanawanita.png";
import bajutidur from "../../asset/bajutidur.png";
import Hoodie from "../../asset/hodie.png";
import Jaket from "../../asset/jaket.png";
import beachwaer from "../../asset/beachwaer.png";
import dress from "../../asset/dress.png";
import rok from "../../asset/rok.png";
import jeans from "../../asset/jeans.png";
import knitwears from "../../asset/knitewear.png";
import blazer from "../../asset/blazzer.png";

const App = () => {
	const navigate = useNavigate(); // Hook untuk navigasi
	const [selectedProduct, setSelectedProduct] = useState(null); // State untuk popup
	const products = [
		{
			name: "Atasan Wanita",
			image: atasanwanita,
			description: `Tampil anggun dan stylish dengan Atasan Wanita kami yang dirancang untuk menonjolkan gaya modern dan elegan. Terbuat dari bahan berkualitas tinggi yang nyaman di kulit, atasan ini tersedia dalam berbagai model—mulai dari kasual hingga formal—cocok untuk berbagai acara. Desain yang fashionable dan potongan yang pas memberikan siluet yang flattering untuk setiap bentuk tubuh. Lengkapi koleksi fashion Anda dengan atasan wanita yang memadukan kenyamanan dan gaya, sempurna untuk menemani hari-hari Anda dalam setiap kesempatan!`,
			bahan: "Katun, Poliester",
		},
		{
			name: "Playsuits & Jumpsuits",
			image: jumpsuits,
			description: `Tampil chic dan effortlessly stylish dengan koleksi Playsuits & Jumpsuits wanita kami! Dirancang untuk memberikan tampilan yang trendi sekaligus praktis, outfit one-piece ini sangat cocok untuk Anda yang menginginkan gaya yang simpel namun tetap fashionable. Terbuat dari bahan yang nyaman dan ringan, playsuits cocok untuk tampilan kasual sehari-hari, sementara jumpsuits dengan desain elegan sempurna untuk acara yang lebih formal. Dapatkan kemudahan berbusana tanpa mengorbankan gaya dengan koleksi Playsuits & Jumpsuits kami yang siap membuat Anda tampil memukau dalam segala suasana!`,
			bahan: "Katun, Poliester",
		},
		{
			name: "Celana & Legging",
			image: celana,
			description: `Nikmati kenyamanan maksimal dan fleksibilitas dengan Celana & Legging wanita kami. Terbuat dari bahan elastis berkualitas yang mengikuti bentuk tubuh, celana ini cocok untuk berbagai aktivitas, baik untuk olahraga maupun bersantai. Desainnya yang stylish menjadikannya pilihan sempurna untuk dipadukan dengan berbagai outfit sehari-hari. Tampil modis tanpa mengorbankan kenyamanan dengan celana & legging kami yang ringan dan fleksibel!`,
			bahan: "Kapas, Spandeks",
		},
		{
			name: "Baju Tidur",
			image: bajutidur,
			description: `Tidur lebih nyaman dan nyenyak dengan Baju Tidur wanita kami yang lembut dan breathable. Terbuat dari bahan yang ringan dan halus di kulit, baju tidur ini memastikan Anda merasa nyaman sepanjang malam. Desain yang manis dan simpel menjadikannya pilihan sempurna untuk bersantai di rumah, tanpa mengorbankan gaya. Dapatkan kenyamanan tidur maksimal dengan koleksi Baju Tidur kami!`,
			bahan: "Sutra, Katun",
		},
		{
			name: "Hoodie & Sweatshirt",
			image: Hoodie,
			description: `Tetap hangat dan stylish dengan koleksi Hoodie & Sweatshirt wanita kami. Dibuat dari bahan tebal namun lembut, hoodie ini memberikan kehangatan maksimal tanpa mengurangi kenyamanan. Desain kasual dan modern cocok untuk dipakai sehari-hari atau saat beraktivitas di luar. Lengkapi gaya santai Anda dengan Hoodie & Sweatshirt yang nyaman dan trendy ini!`,
			bahan: "Katun, Poliester",
		},
		{
			name: "Jaket & Coats",
			image: Jaket,
			description: `Tampil elegan dan percaya diri dengan Jaket & Coats wanita kami. Didesain untuk memberikan kehangatan sekaligus tampilan stylish, koleksi ini menggunakan bahan berkualitas tinggi yang tahan lama. Cocok untuk cuaca dingin maupun saat ingin tampil lebih formal. Jadikan jaket dan coat ini sebagai pilihan utama Anda dalam berbusana sehari-hari atau untuk acara khusus!`,
			bahan: "Wol, Poliester",
		},
		{
			name: "Swimwear & Beachwear",
			image: beachwaer,
			description: `Tampil percaya diri dan stylish di pantai dengan koleksi Swimwear & Beachwear kami. Didesain dengan potongan yang modern dan bahan yang cepat kering, swimwear ini memberikan kenyamanan saat berenang atau bersantai di tepi pantai. Tersedia dalam berbagai model yang trendy, koleksi ini siap membuat Anda terlihat fashionable di setiap momen santai Anda!`,
			bahan: "Nilon, Spandeks",
		},
		{
			name: "Dress",
			image: dress,
			description: `Tampil feminin dan elegan dengan koleksi Dress wanita kami yang dirancang untuk berbagai kesempatan. Dengan potongan yang mempesona dan bahan yang nyaman di kulit, dress ini cocok untuk acara formal maupun kasual. Baik itu untuk pesta, makan malam, atau sekadar hangout, dress kami akan membuat Anda tampil memukau dan percaya diri!`,
			bahan: "Katun, Poliester",
		},
		{
			name: "Rok",
			image: rok,
			description: `Tampil anggun dan modis dengan koleksi Rok wanita kami. Terbuat dari bahan yang ringan dan nyaman, rok ini tersedia dalam berbagai desain, mulai dari kasual hingga formal. Desain yang modern dan potongan yang pas akan membuat penampilan Anda semakin stylish di setiap kesempatan. Pilih rok yang tepat untuk menyempurnakan gaya Anda!`,
			bahan: "Katun, Poliester",
		},
		{
			name: "Jeans",
			image: jeans,
			description: `Jeans wanita kami dirancang untuk memberikan kenyamanan dan gaya dalam satu paket. Terbuat dari bahan denim berkualitas yang fleksibel, jeans ini cocok untuk berbagai aktivitas dan mudah dipadukan dengan berbagai outfit. Baik untuk tampilan kasual sehari-hari atau untuk acara santai, jeans kami adalah pilihan sempurna untuk gaya yang selalu on point!`,
			bahan: "Denim",
		},
		{
			name: "Knitwear & Cardigan",
			image: knitwears,
			description: `Tampil cozy dan stylish dengan Knitwear & Cardigan wanita kami. Dibuat dari bahan rajut berkualitas, koleksi ini memberikan kehangatan dan kenyamanan ekstra di setiap kesempatan. Cocok dipadukan dengan berbagai outfit untuk tampilan yang lebih kasual maupun formal. Knitwear & Cardigan kami siap menyempurnakan gaya layering Anda!`,
			bahan: "Akrilik, Wol",
		},
		{
			name: "Blazer",
			image: blazer,
			description: `Tampilkan sisi profesional dan elegan Anda dengan Blazer wanita kami. Didesain untuk memberikan siluet yang pas dan modern, blazer ini sangat cocok untuk dipakai di kantor atau acara formal. Terbuat dari bahan berkualitas yang nyaman dipakai sepanjang hari, blazer kami akan meningkatkan kepercayaan diri Anda dalam berbagai kesempatan. Jadikan blazer ini pilihan utama untuk melengkapi tampilan formal Anda!`,
			bahan: "Katun, Poliester",
		},
	];

	// Fungsi untuk menutup popup
	const closePopup = () => {
		setSelectedProduct(null);
	};

	// Fungsi untuk menangani tombol konsultasi
	const handleConsultation = () => {
		navigate("/register"); // Navigasi ke rute "/register"
	};

	return (
		<>
			<div className="container">
				<div className="header-row">
					<h1 className="title">Semua Produk Kategori Pakaian Wanita</h1>
					{/* Tombol Back */}
					<button
						onClick={() => navigate(-1)}
						className="back-button"
					>
						Back
					</button>
				</div>
				<div className="product-list1">
					{products.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)} // Set produk yang dipilih saat diklik
						>
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>

				{/* Popup untuk menampilkan detail produk yang dipilih */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.description}</p>
									<p>
										<strong>Bahan:</strong> {selectedProduct.bahan}
									</p>{" "}
									{/* Display Bahan */}
									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Konsultasi
									</button>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* <div className="pagination">
					<button>&larr;</button>
					<span>1</span>
					<span>2</span>
					<button>&rarr;</button>
				</div> */}
			</div>
		</>
	);
};

export default App;
