import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Register.css";

const SCRIPT_URL =
	"https://script.google.com/macros/s/AKfycbz4VsZvFcrP8OGVKU-UotjAQMBILiWckDu8v7qKwoIeFQf2N9utkbpdnvpJW0MBR8IO/exec";

function Register() {
	const [formData, setFormData] = useState({
		email: "",
		namaLengkap: "",
		nomorAktif: "",
		namaCompany: "",
		product: "celana",
		jenis: "order-product",
		jadwal: "",
		catatan: "",
	});
	const [showPopup, setShowPopup] = useState(false);
	const navigate = useNavigate();

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dataToSend = {
			email: formData.email,
			namaLengkap: formData.namaLengkap,
			nomorAktif: formData.nomorAktif,
			namaCompany: formData.namaCompany,
			product: formData.product,
			jenis: formData.jenis,
			jadwal: formData.jadwal,
			catatan: formData.catatan,
		};

		try {
			await fetch(SCRIPT_URL, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(dataToSend),
				mode: "no-cors", // Menghindari preflight request
			});
			setShowPopup(true);

			// Redirect ke halaman utama setelah 2 detik
			setTimeout(() => {
				setShowPopup(false);
				navigate("/");
			}, 2000);
		} catch (error) {
			console.error("Error submitting data", error);
		}
	};

	return (
		<div className="form-container">
			<h2 className="form-header">Form Pendaftaran Mitra</h2>
			<form
				onSubmit={handleSubmit}
				className="form"
			>
				<div className="form-group">
					<label htmlFor="email">Email</label>
					<input
						type="email"
						id="email"
						name="email"
						value={formData.email}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="nama-lengkap">Nama Lengkap</label>
					<input
						type="text"
						id="nama-lengkap"
						name="namaLengkap"
						value={formData.namaLengkap}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="nomor-aktif">Nomor Aktif</label>
					<input
						type="tel"
						id="nomor-aktif"
						name="nomorAktif"
						value={formData.nomorAktif}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="nama-company">Nama Perusahaan</label>
					<input
						type="text"
						id="nama-company"
						name="namaCompany"
						value={formData.namaCompany}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="product">Produk yang Digunakan</label>
					<select
						id="product"
						name="product"
						value={formData.product}
						onChange={handleChange}
						required
					>
						<option value="Tas Wanita">Tas wanita</option>
						<option value="Pakaian Wanita">Pakaian wanita</option>
						<option value="Sepatu Wanita">Sepatu Wanita</option>
						<option value="Aksesoris Wanita">Aksesoris Wanita</option>
					</select>
				</div>
				<div className="form-group">
					<label htmlFor="jenis">Jenis yang Digunakan</label>
					<select
						id="jenis"
						name="jenis"
						value={formData.jenis}
						onChange={handleChange}
						required
					>
						<option value="order-product">Order Produk</option>
						<option value="konsultasi-product">Konsultasi Produk</option>
					</select>
				</div>
				<div className="form-group">
					<label htmlFor="jadwal">Jadwal Konsultasi / Meet dengan Kami</label>
					<input
						type="date"
						id="jadwal"
						name="jadwal"
						value={formData.jadwal}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="catatan">Catatan</label>
					<textarea
						id="catatan"
						name="catatan"
						rows="4"
						value={formData.catatan}
						onChange={handleChange}
						placeholder="Masukkan catatan tambahan jika ada"
					></textarea>
				</div>
				<button
					type="submit"
					className="submit-btn"
				>
					Daftar
				</button>
			</form>

			{/* Popup Sederhana */}
			{showPopup && (
				<div className="popup-cok">
					<div className="popup-content-cok">
						<h3>Data anda telah dikirim ke admin !</h3>
					</div>
				</div>
			)}
		</div>
	);
}

export default Register;
