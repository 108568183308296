import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Fitur.css"; // Pastikan CSS telah ditetapkan
import User from "../../asset/User.png";

// URL Google Apps Script
const SCRIPT_URL =
	"https://script.google.com/macros/s/AKfycbylJ-6GDHxbZsBUcOTA_dLCNPcRRESJd3L7C6Ru_oyu8bnIhtcxL0DfEYhG9HgkiiBS/exec"; // Ganti dengan URL Google Apps Script Anda

const App = () => {
	const navigate = useNavigate();
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [idMitra, setIdMitra] = useState(""); // State untuk ID Mitra
	const [isSuccess, setIsSuccess] = useState(false); // State untuk notifikasi berhasil

	// Data produk
	const products = [
		{
			Kategori: "KOL",
			name: "Dwi Putra",
			image: User,
			Lokasi: "Surabaya, Jawa Timur",
			Harga: "Range 10k - 50k",
			IG: "@dwiputra",
		},
		{
			Kategori: "KOL",
			name: "Sinta Lestari",
			image: User,
			Lokasi: "Yogyakarta, DI Yogyakarta",
			Harga: "Range 50k - 100k",
			IG: "@sintalestari",
		},
		{
			Kategori: "KOL",
			name: "Bayu Pratama",
			image: User,
			Lokasi: "Jakarta, DKI Jakarta",
			Harga: "Range 100k - 150k",
			IG: "@bayupratama",
		},
		{
			Kategori: "KOL",
			name: "Lina Kartika",
			image: User,
			Lokasi: "Semarang, Jawa Tengah",
			Harga: "Range 150k - 200k",
			IG: "@linakartika",
		},
		{
			Kategori: "KOL",
			name: "Andre Kurniawan",
			image: User,
			Lokasi: "Medan, Sumatera Utara",
			Harga: "Range 200k - 250k",
			IG: "@andrekurniawan",
		},
		{
			Kategori: "KOL",
			name: "Putri Ayu",
			image: User,
			Lokasi: "Palembang, Sumatera Selatan",
			Harga: "Range 10k - 50k",
			IG: "@putriayu",
		},
		{
			Kategori: "KOL",
			name: "Rendi Wicaksono",
			image: User,
			Lokasi: "Makassar, Sulawesi Selatan",
			Harga: "Range 50k - 100k",
			IG: "@rendiwicaksono",
		},
		{
			Kategori: "KOL",
			name: "Dewi Anggraini",
			image: User,
			Lokasi: "Denpasar, Bali",
			Harga: "Range 100k - 150k",
			IG: "@dewianggraini",
		},
		{
			Kategori: "KOL",
			name: "Satria Wijaya",
			image: User,
			Lokasi: "Balikpapan, Kalimantan Timur",
			Harga: "Range 150k - 200k",
			IG: "@satriawijaya",
		},
		{
			Kategori: "KOL",
			name: "Fani Rizky",
			image: User,
			Lokasi: "Pekanbaru, Riau",
			Harga: "Range 200k - 250k",
			IG: "@fanirizky",
		},

		// Tambahkan produk lain sesuai kebutuhan...
	];

	// Fungsi untuk menutup popup
	const closePopup = () => {
		setSelectedProduct(null);
		setIdMitra(""); // Reset ID Mitra saat popup ditutup
		setIsSuccess(false); // Reset notifikasi ketika popup ditutup
	};

	// Fungsi untuk mengirimkan data ke Google Sheets
	const handleConsultation = async () => {
		const dateNow = new Date().toLocaleDateString("id-ID"); // Mendapatkan tanggal saat ini
		const dataToSend = {
			idMitra: idMitra,
			productName: selectedProduct?.name,
			category: selectedProduct?.Kategori, // Mengirimkan data kategori
			date: dateNow,
		};

		try {
			await axios.post(SCRIPT_URL, dataToSend, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log("Data berhasil dikirim:", dataToSend);
			setIsSuccess(true); // Tampilkan notifikasi berhasil
		} catch (error) {
			console.error("Error submitting data", error);
		}
	};

	return (
		<>
			<div className="container">
				<div className="header-row">
					<h1 className="title">Mitra KOL Kami</h1>
					<button
						onClick={() => navigate(-1)}
						className="back-button"
					>
						Back
					</button>
				</div>
				<div className="product-list1">
					{products.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)} // Set produk yang dipilih saat diklik
						>
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>

				{/* Popup untuk menampilkan detail produk yang dipilih */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.Lokasi}</p>
									<p>{selectedProduct.Harga}</p>
									<p>
										<strong>Portofolio:</strong> {selectedProduct.IG}
									</p>
									<label htmlFor="idMitra">ID Mitra:</label>
									<input
										type="text"
										id="idMitra"
										value={idMitra}
										onChange={(e) => setIdMitra(e.target.value)}
										placeholder="Masukkan ID Mitra"
									/>
									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Hubungi
									</button>
								</div>
								{isSuccess && (
									<div className="success-message">Data berhasil dikirim!</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default App;
