// components/CallToAction.js
import React from "react";
import "./CallToAction.css";
import call1 from "../asset/call-action.png";
import call2 from "../asset/call-action1.png";

function CallToAction() {
	return (
		<>
			{/* CTA Section 1 */}
			<section className="call-to-action">
				<div className="container">
					<div className="cta-content">
						<div className="cta-image">
							<img
								src={call1}
								alt="Illustration of Partner"
								className="cta-illustration"
							/>
						</div>
						<div className="cta-text">
							<h2>Ingin Konsultasi Product ?</h2>
							<p>
								Sebelum menjadi patner kami, kami membuka sesi konsultasi
								terkait kebutuhan pasar dan pengetahuan dasar untuk mengenal
								product anda dalam segi tata kelola bisnis yang akan anda
								jalankan.
							</p>
							<a
								href="daftar.v1.html"
								className="button"
							>
								Konsultasi sekarang
							</a>
						</div>
					</div>
				</div>
			</section>

			{/* CTA Section 2 */}
			<section className="call-to-action">
				<div className="container">
					<div className="cta-content">
						<div className="cta-image">
							<img
								src={call2}
								alt="Illustration of Partner"
								className="cta-illustration"
							/>
						</div>
						<div className="cta-text">
							<h2>Ingin Menjadi Mitra Kami ?</h2>
							<p>
								Ketika menjadi mitra kami, anda tidak hanya mendapatkan product
								murah dan berkalitas kami. namun akan mendapatkan semua
								fasilitas seperti aplikasi management, aplikasi riset pasar, dan
								edukasi secara gratis untuk membatu anda mengembangkan pasar
								anda.
							</p>
							<a
								href="daftar.v1.html"
								className="button"
							>
								Gabung sekarang
							</a>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default CallToAction;
