import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Fitur.css"; // Pastikan CSS telah ditetapkan
import User from "../../asset/User.png";

// URL Google Apps Script
const SCRIPT_URL =
	"https://script.google.com/macros/s/AKfycbylJ-6GDHxbZsBUcOTA_dLCNPcRRESJd3L7C6Ru_oyu8bnIhtcxL0DfEYhG9HgkiiBS/exec"; // Ganti dengan URL Google Apps Script Anda

const App = () => {
	const navigate = useNavigate();
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [idMitra, setIdMitra] = useState(""); // State untuk ID Mitra
	const [isSuccess, setIsSuccess] = useState(false); // State untuk notifikasi berhasil

	// Data produk
	const products = [
		{
			Kategori: "Influencer",
			name: "Budi Santoso",
			image: User,
			Lokasi: "Banda Aceh, Aceh",
			Harga: "Range 10k - 50k",
			IG: "@budisantoso",
		},
		{
			Kategori: "Influencer",
			name: "Mira Susanti",
			image: User,
			Lokasi: "Padang, Sumatera Barat",
			Harga: "Range 50k - 100k",
			IG: "@mirasusanti",
		},
		{
			Kategori: "Influencer",
			name: "Ahmad Zainuddin",
			image: User,
			Lokasi: "Pontianak, Kalimantan Barat",
			Harga: "Range 100k - 150k",
			IG: "@ahmadzainuddin",
		},
		{
			Kategori: "Influencer",
			name: "Siti Nurhaliza",
			image: User,
			Lokasi: "Manado, Sulawesi Utara",
			Harga: "Range 150k - 200k",
			IG: "@sitinurhaliza",
		},
		{
			Kategori: "Influencer",
			name: "Yusuf Hadi",
			image: User,
			Lokasi: "Jayapura, Papua",
			Harga: "Range 200k - 250k",
			IG: "@yusuphadi",
		},
		{
			Kategori: "Influencer",
			name: "Dina Lestari",
			image: User,
			Lokasi: "Mataram, Nusa Tenggara Barat",
			Harga: "Range 10k - 50k",
			IG: "@dinalestari",
		},
		{
			Kategori: "Influencer",
			name: "Agus Pratama",
			image: User,
			Lokasi: "Palangkaraya, Kalimantan Tengah",
			Harga: "Range 50k - 100k",
			IG: "@aguspratama",
		},
		{
			Kategori: "Influencer",
			name: "Nadia Firdaus",
			image: User,
			Lokasi: "Ternate, Maluku Utara",
			Harga: "Range 100k - 150k",
			IG: "@nadiafirdaus",
		},
		{
			Kategori: "Influencer",
			name: "Faisal Akbar",
			image: User,
			Lokasi: "Kupang, Nusa Tenggara Timur",
			Harga: "Range 150k - 200k",
			IG: "@faisalakbar",
		},
		{
			Kategori: "Influencer",
			name: "Elvira Sari",
			image: User,
			Lokasi: "Ambon, Maluku",
			Harga: "Range 200k - 250k",
			IG: "@elvirasari",
		},

		// Tambahkan produk lain sesuai kebutuhan...
	];

	// Fungsi untuk menutup popup
	const closePopup = () => {
		setSelectedProduct(null);
		setIdMitra(""); // Reset ID Mitra saat popup ditutup
		setIsSuccess(false); // Reset notifikasi ketika popup ditutup
	};

	// Fungsi untuk mengirimkan data ke Google Sheets
	const handleConsultation = async () => {
		const dateNow = new Date().toLocaleDateString("id-ID"); // Mendapatkan tanggal saat ini
		const dataToSend = {
			idMitra: idMitra,
			productName: selectedProduct?.name,
			category: selectedProduct?.Kategori, // Mengirimkan data kategori
			date: dateNow,
		};

		try {
			await axios.post(SCRIPT_URL, dataToSend, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			console.log("Data berhasil dikirim:", dataToSend);
			setIsSuccess(true); // Tampilkan notifikasi berhasil
		} catch (error) {
			console.error("Error submitting data", error);
		}
	};

	return (
		<>
			<div className="container">
				<div className="header-row">
					<h1 className="title">Mitra Influencer Kami</h1>
					<button
						onClick={() => navigate(-1)}
						className="back-button"
					>
						Back
					</button>
				</div>
				<div className="product-list1">
					{products.map((product, index) => (
						<div
							className="product-card"
							key={index}
							onClick={() => setSelectedProduct(product)} // Set produk yang dipilih saat diklik
						>
							<div className="image-container">
								<img
									src={product.image}
									alt={`${product.name} Image`}
								/>
							</div>
							<h3>{product.name}</h3>
						</div>
					))}
				</div>

				{/* Popup untuk menampilkan detail produk yang dipilih */}
				{selectedProduct && (
					<div className="popup-overlay">
						<div className="popup">
							<button
								className="close-button"
								onClick={closePopup}
							>
								&times;
							</button>
							<div className="popup-content">
								<img
									src={selectedProduct.image}
									alt={`${selectedProduct.name} Image`}
									className="popup-image"
								/>
								<div className="popup-details">
									<h3>{selectedProduct.name}</h3>
									<p>{selectedProduct.Lokasi}</p>
									<p>{selectedProduct.Harga}</p>
									<p>
										<strong>Portofolio:</strong> {selectedProduct.IG}
									</p>
									<label htmlFor="idMitra">ID Mitra:</label>
									<input
										type="text"
										id="idMitra"
										value={idMitra}
										onChange={(e) => setIdMitra(e.target.value)}
										placeholder="Masukkan ID Mitra"
									/>
									<button
										className="consultation-button"
										onClick={handleConsultation}
									>
										Hubungi
									</button>
								</div>
								{isSuccess && (
									<div className="success-message">Data berhasil dikirim!</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default App;
