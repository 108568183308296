// components/HeroSection.js
import React from "react";
import "./HeroSection.css";
import Hero from "../asset/bg-home.png";

function HeroSection() {
	return (
		<header className="hero">
			<div className="hero-text">
				<h1>Solusi Lengkap Segala Kebutuhan Anda</h1>
				<p>Coba fitur kami secara gratis dengan mendaftar sebagai mitra.</p>
				<a
					href="/register"
					className="button"
				>
					Gabung Mitra
				</a>
			</div>
			<div className="hero-image">
				<img
					src={Hero}
					alt="Illustration of person coding on a computer"
				/>
			</div>
		</header>
	);
}

export default HeroSection;
